/* eslint-disable react-hooks/exhaustive-deps -- ignore deps */
"use client";

import { useCallback } from "react";
import NextImage from "next/image";
import { Button } from "../../ui/button";
import { Modal } from "../../util/modal";
import WorkflowDemoIframe from "./workflow-demo-iframe";
import {
  useWorkflowDemo,
  type UseWorkflowDemoProps,
} from "./use-workflow-demo";

export type WorkflowDemoProps = UseWorkflowDemoProps;

function WorkflowDemo(props: WorkflowDemoProps) {
  const {
    className,
    styles,
    runDemoLabel,
    loadFrame,
    handleCTAClick,
    demoUrl,
    demoCoverImg,
    demoCoverImgAlt,
    noFrame,
    modalActive,
    handleShowModal,
    handleCloseModal,
  } = useWorkflowDemo(props);

  const Iframe = useCallback(
    () => (
      <WorkflowDemoIframe
        className={styles.frame({ className })}
        demoUrl={demoUrl}
        title={runDemoLabel}
      />
    ),
    []
  );

  return (
    <div className={styles.container()}>
      {loadFrame ? (
        <>
          <div className="aspect-w-16 aspect-h-9 relative">
            <Iframe />
            <Button
              appearance="solid"
              className={styles.btnModal()}
              color="white"
              id="btn-modal"
              onClick={handleShowModal}
              rounded
              startIcon="arrows-maximize"
            />
          </div>

          <Modal active={modalActive} handleClose={handleCloseModal}>
            <div className="aspect-w-16 aspect-h-9">
              <Iframe />
            </div>
          </Modal>
        </>
      ) : (
        <div className={styles.cover()}>
          <NextImage
            alt={demoCoverImgAlt}
            className={styles.image()}
            fill
            sizes="100vw"
            src={demoCoverImg}
          />
          {!!runDemoLabel && (
            <div className={styles.btnWrapper()}>
              <Button
                appearance="solid"
                color="gray"
                onClick={handleCTAClick}
                size="md"
              >
                {runDemoLabel}
              </Button>
            </div>
          )}
        </div>
      )}
      {noFrame && (
        <Modal active={modalActive} handleClose={handleCloseModal}>
          <div className="aspect-w-16 aspect-h-9">
            <Iframe />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default WorkflowDemo;
