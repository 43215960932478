import { Button } from "../../ui/button";
import { useSwiperNav, type UseSwiperNavProps } from "./use-swiper-nav";

export type SwiperNavProps = UseSwiperNavProps;

function ModuleCard(props: SwiperNavProps) {
  const { id, className, styles, buttonColor } = useSwiperNav(props);

  return (
    <div
      className={styles.base({ className })}
      data-component-name="swiper-nav"
    >
      <Button
        appearance="solid"
        className={`prev-carousel-${id}`}
        color={buttonColor}
        rounded
        size="md"
        startIcon="arrow-left"
        type="button"
      />
      <Button
        appearance="solid"
        className={`next-carousel-${id}`}
        color={buttonColor}
        rounded
        size="md"
        startIcon="arrow-right"
        type="button"
      />
    </div>
  );
}

export default ModuleCard;
