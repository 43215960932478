import { tv, type VariantProps } from "../../../util/tv";

export const modal = tv({
  slots: {
    base: ["relative z-[2999]"],
    overlay: [
      "fixed inset-0 transition-opacity",
      "bg-gray-100 bg-opacity-70",
      "overflow-y-auto",
    ],
    wrapper: ["flex justify-center", "p-6"],
    content: [
      "relative transform rounded-3xl transition-all",
      "bg-white shadow-xl",
      "sm:my-8 w-full",
    ],
    close: [
      "flex justify-center items-center",
      "absolute top-1 right-1 z-50 w-8 h-8",
      "text-white",
      "border-[3px] border-white border-solid",
      "bg-black hover:bg-gray-900",
      "transition-colors duration-300",
      "rounded-full outline-none lg:focus:outline-offset-0 lg:focus:outline-acqua-500",
    ],
    icon: ["w-4 h-4"],
  },
  variants: {
    size: {
      xs: {
        content: ["max-w-xs"],
      },
      sm: {
        content: ["max-w-md"],
      },
      md: {
        content: ["max-w-xl"],
      },
      lg: {
        content: ["max-w-3xl"],
      },
      xl: {
        content: ["max-w-6xl"],
      },
      full: {
        content: ["max-w-full"],
      },
    },
    centered: {
      true: {
        wrapper: ["items-center min-h-full"],
      },
    },
  },
  defaultVariants: {
    size: "xl",
    centered: true,
  },
});

export type ModalVariantProps = VariantProps<typeof modal>;
