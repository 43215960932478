"use client";

import { Vector } from "../.././ui/vector";
import { Spinner } from "../../util/spinner";
import { Text } from "../../ui/text";
import {
  useMoisesPlayer,
  type useMoisesPlayerProps,
} from "./use-moises-player";

export type MoisesPlayerProps = useMoisesPlayerProps;

const MoisesPlayer = (props: MoisesPlayerProps) => {
  const {
    className,
    styles,
    icon,
    tracks,
    containerRefs,
    isMultiTrackPlayer,
    wavesurfer,
  } = useMoisesPlayer(props);

  const {
    currentWave,
    volumes,
    setVolume,
    isAllTracksReady,
    isAllTracksPlaying,
    playPauseAll,
  } = wavesurfer;

  return (
    <div className={styles.base({ className })}>
      {!!icon && <Vector name={icon} className={styles.icon()} />}
      <button
        className={styles.playPlause()}
        onClick={playPauseAll}
        disabled={!isAllTracksReady}
      >
        <Vector
          className={styles.playPauseIcon()}
          name={isAllTracksPlaying ? "pause" : "play-audio"}
        />
      </button>
      <div className={styles.waves()}>
        {!isAllTracksReady && (
          <Spinner color="light" className={styles.spinner()} />
        )}
        <div className={styles.wavesGroup()}>
          {tracks.map((_, index) => (
            <div
              key={index}
              className={styles.wave({
                className: currentWave === index ? "block" : "hidden",
              })}
              ref={(el) => {
                containerRefs.current[index] = el;
              }}
            />
          ))}
        </div>
      </div>
      <div className={styles.volumes()}>
        {tracks.map(({ title }, index) => {
          if (isMultiTrackPlayer && index === 0) return null;

          return (
            <div key={index} className={styles.volume()}>
              {!!title && (
                <Text
                  tag="label"
                  size="label"
                  weight="medium"
                  className={styles.label()}
                >
                  {title}
                </Text>
              )}
              <input
                className={styles.range()}
                type="range"
                min="0"
                max="1"
                step="0.01"
                defaultValue={volumes[index]}
                onChange={(e) => setVolume(index, parseFloat(e.target.value))}
                style={{
                  //@ts-ignore
                  "--range-value": `${(volumes[index] || 0) * 100}%`,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MoisesPlayer;
