"use client";

import { m } from "framer-motion";
import { useVideo, type UseVideoProps } from "./use-video";

export type VideoProps = UseVideoProps;

function Video(props: VideoProps) {
  const {
    ref,
    className,
    containerClassName,
    styles,
    handlers,
    src,
    mime,
    componentProps,
  } = useVideo(props);

  return (
    <m.div
      className={styles.base({ className: containerClassName })}
      onViewportEnter={() => {
        handlers.start();
      }}
      onViewportLeave={() => {
        handlers.pause();
      }}
    >
      <video
        className={styles.video({ className })}
        ref={ref}
        {...componentProps}
      >
        <source src={src} type={mime} />
      </video>
    </m.div>
  );
}

export default Video;
