import type { ButtonVariantProps } from "../../ui/button/button.variants";
import {
  swiperNavVariant,
  type SwiperNavVariantProps,
} from "./swiper-nav.variants";

export type UseSwiperNavProps = {
  className?: string;
  id: string | number;
} & SwiperNavVariantProps;

export const useSwiperNav = (props: UseSwiperNavProps) => {
  const { id, className = "", theme = "dark" } = props;

  const styles = swiperNavVariant({ theme });

  const buttonColor: ButtonVariantProps["color"] =
    theme === "dark" ? "gray" : "white";

  return {
    id,
    styles,
    className,
    buttonColor,
  };
};
