"use client";

import { useState, useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import type { SectionsRichContentSlider } from "../../../types";
import { RichContentCard } from "../../common/rich-content-card";
import RichContentSliderNav from "./rich-content-slider.nav";
import { RichContentSliderVariantReturnProps } from "./rich-content-slider.variants";
import "swiper/css/pagination";
import "swiper/css/navigation";

export type RichContentSliderSwiperProps = {
  styles: RichContentSliderVariantReturnProps;
  sectionName: string;
  data: SectionsRichContentSlider;
};

const RichContentSliderSwiper = ({
  styles,
  sectionName,
  data,
}: RichContentSliderSwiperProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { id, theme, cards = [] } = data;

  const swiperRef = useRef(null);
  const controls = useAnimation();
  const inView = useInView(swiperRef, {
    amount: 0.5,
    once: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({
        x: [0, -160, 0],
        transition: {
          ease: "easeInOut",
          duration: 0.6,
        },
      });
    }
  }, [controls, inView]);

  function onSlideChange(slide: any) {
    setCurrentIndex(slide.activeIndex);
  }

  return (
    <Swiper
      ref={swiperRef}
      modules={[Navigation, Pagination]}
      spaceBetween={20}
      initialSlide={0}
      slidesPerView={1}
      simulateTouch={false}
      speed={2000}
      navigation={{
        prevEl: `.prev-carousel-${id}`,
        nextEl: `.next-carousel-${id}`,
      }}
      breakpoints={{
        0: {
          speed: 600,
        },
        768: {
          speed: 1000,
        },
        1200: {
          speed: 2000,
        },
      }}
      pagination={{
        el: ".swiper-dots",
        clickable: true,
        renderBullet: (index, className) =>
          `<span class="${styles.bullet({ className })}">${index + 1}</span>`,
      }}
      onSlideChange={onSlideChange}
    >
      <span slot="container-start" className="mb-8">
        <RichContentSliderNav
          currentIndex={currentIndex}
          theme={theme}
          cards={cards}
        />
      </span>
      {cards.map((card) => (
        <SwiperSlide
          key={`icon-card-${card.id}`}
          // @ts-ignore
          tag={motion.div}
          animate={controls}
          className={styles.card()}
        >
          <RichContentCard
            sectionName={sectionName}
            theme={theme}
            data={card}
          />
        </SwiperSlide>
      ))}
      <div className={styles.bullets({ className: "swiper-dots" })} />
    </Swiper>
  );
};

export default RichContentSliderSwiper;
