import { tv, type VariantProps } from "../../../util/tv";

export const swiperNavVariant = tv({
  slots: {
    base: ["text-left gap-x-3"],
  },
  variants: {
    theme: {
      dark: {},
      light: {},
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export type SwiperNavVariantProps = VariantProps<typeof swiperNavVariant>;
