import { CSSProperties, useMemo } from "react";
import type { SectionProps, RichContentCard } from "../../../types";
import {
  RichContentCardVariantProps,
  richContentCard,
} from "./rich-content-card.variants";
import { getMediaType } from "../../../util/media";

export type useRichContentCardProps = SectionProps<
  RichContentCard,
  RichContentCardVariantProps & {
    cardStyle?: CSSProperties;
  }
>;

export const useRichContentCard = (props: useRichContentCardProps) => {
  const {
    className = "",
    theme = "dark",
    sectionName,
    data: {
      background,
      backgroundMobile,
      backgroundSize,
      demoJobId,
      demoCTALabel,
      ...restData
    },
  } = props;

  const backgroundType = !!demoJobId
    ? ("demo" as RichContentCardVariantProps["type"])
    : getMediaType(background?.url as string);

  const styles = useMemo(
    () =>
      richContentCard({
        theme,
        background: backgroundSize,
        type: backgroundType,
      }),
    [theme, backgroundSize, backgroundType]
  );

  const hasMedia = !!background || !!backgroundMobile;

  return {
    theme,
    className,
    sectionName,
    hasMedia,
    backgroundType,
    data: {
      ...restData,
      background,
      backgroundMobile,
      backgroundSize,
      demoJobId,
      demoCTALabel,
    },
    styles,
  };
};
