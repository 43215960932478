/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import { useEffect, useRef } from "react";
import { motion, animate, useMotionValue } from "framer-motion";
import { useWindowSize } from "react-use";
import type { SectionsRichContentSlider } from "../../../types";
import { useSwiper } from "swiper/react";
import { richContentSliderNav } from "./rich-content-slider.variants";

type RichContentSliderNavProps = Pick<
  SectionsRichContentSlider,
  "theme" | "cards"
> & {
  currentIndex: number;
};

const RichContentSliderNav = ({
  theme,
  cards,
  currentIndex,
}: RichContentSliderNavProps) => {
  const itemRefs = useRef<HTMLLIElement[]>([]);
  const itemWidth = useMotionValue(0);
  const itemPosition = useMotionValue(0);
  const { width } = useWindowSize();
  const swiper = useSwiper();

  const styles = richContentSliderNav({ theme });

  const updateSlider = (width: number, left: number) => {
    animate(itemWidth, width);
    animate(itemPosition, left);
  };

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, cards.length);
  }, [cards]);

  useEffect(() => {
    updateSlider(
      itemRefs?.current[currentIndex]?.offsetWidth || 0,
      itemRefs?.current[currentIndex]?.offsetLeft || 0
    );
  }, [currentIndex, width]);

  return (
    <nav className={styles.base()}>
      <ul className={styles.list()}>
        {cards.map(({ id, title }, i) => (
          <li
            key={id}
            ref={(el) => {
              if (el) itemRefs.current[i] = el;
            }}
            onClick={() => swiper.slideTo(i)}
          >
            <span className={styles.anchor()}>{title}</span>
          </li>
        ))}
      </ul>
      <motion.div
        className={styles.bubble()}
        style={{
          width: itemWidth,
          x: itemPosition,
        }}
      />
    </nav>
  );
};

export default RichContentSliderNav;
